// @flow
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Button, TableColumn } from '@vadiun/react-components';
import { UseTableQueryResult } from '@vadiun/react-datatable';
import { ViajeTrasladoTipoServicio } from 'app/viaje/models';
import * as React from 'react';
import { Table } from 'shared/components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { TransferReservationTableTimesCell } from '../../viajeTraslado/components/TransferReservationTableTimesCell';
import { TransferReservationManagmentModel } from '../models/TransferReservationManagment';
import InfoIcon from '@material-ui/icons/Info';
import { ViajeTrasladosGestionEstado } from '../../viajeTraslado/models/TransferReservationPartial';
import { copyReservationPublicNewLinkToClipboard } from '../../viajeTraslado/services/copyReservationPublicLinkToClipboard';
import { useSnackbar } from 'notistack';
import { tw } from 'utils/tailwindUtils';
import ViajeLLTPaisEdit from './ViajeLLTPaisEdit';

interface Props {
  tableQuery: UseTableQueryResult<TransferReservationManagmentModel[], unknown>;
  onLocationsDetails: (reservation: TransferReservationManagmentModel) => void;
  onReservationCopy: (reservation: TransferReservationManagmentModel) => void;
  onReservationShowDetails: (id: number) => React.ReactNode;
  setCanceledTravelInfo: React.Dispatch<
    React.SetStateAction<ViajeTrasladosGestionEstado | undefined>
  >;
  onOpenEditLLtPais: (reservation: TransferReservationManagmentModel) => void;
}

export function ViajeTrasladoGestionTable({
  tableQuery,
  onLocationsDetails,
  onReservationCopy,
  onReservationShowDetails,
  setCanceledTravelInfo,
  onOpenEditLLtPais
}: Props) {
  const data = (tableQuery.data ?? []).map((reservation) => {
    const passengers = reservation.locations
      .map((location) => location.passengers.map((passenger) => passenger.name))
      .flat();
    return {
      ...reservation,
      schedule: {
        arrival: reservation.reservationDatetime,
        exit: reservation.reservationDatetime
          .clone()
          .add(-reservation.advanceHours, 'hours'),
        end: reservation.arrivalDateTime
      },
      business_unit: reservation.assigned_driver?.businessUnit?.name || '-',
      driver_name: reservation.assigned_driver
        ? `${reservation.assigned_driver?.nombreCompleto} - ${reservation.assigned_driver?.codigo} `
        : '-',
      driver_price:
        reservation.final_prices?.driver_price ||
        reservation.calculated_prices?.driver_price ||
        reservation.estimated_prices?.driver_price,
      passengers,
      passengers_name: passengers.toString(),
      client_name: reservation.client.name,
      addresses: reservation.locations
        .map(
          (location, index) =>
            `${index + 1} - ${createDomicilioText(location.address)}`
        )
        .flat()
        .toString(),
      reservation_datetime:
        reservation.reservationDatetime.format('DD-MM-YY HH:mm')
    };
  });

  const snackbar = useSnackbar();

  const columns: TableColumn<any>[] = [
    {
      name: 'schedule',
      label: 'Horarios',
      options: {
        download: false,
        customBodyRender: (schedule) => {
          return <TransferReservationTableTimesCell schedule={schedule} />;
        }
      }
    },
    {
      name: 'reservation_datetime',
      label: 'Fecha y Horario Reserva',
      options: {
        display: false
      }
    },
    {
      name: 'client_name',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index) => {
          const current = data[index];
          return (
            <div className="flex flex-col gap-2">
              <p
                className={`w-44 text-sm ${
                  !current.pay_with_cash ? 'text-blue-500' : 'text-black'
                }`}
              >
                {current.client.name}
              </p>
              <div
                className={tw('hidden flex-col gap-1', {
                  flex: current.lltpais ? true : false
                })}
              >
                <div className="flex text-sm w-fit text-blue-100 font-bold px-2 py-1 bg-blue-900 rounded-lg mb-1">
                  LLT-País
                </div>
                <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
                  Proveedor
                  <span>
                    ${Number(current?.lltpais?.provider_price ?? 0).toFixed(0)}
                  </span>
                </p>
                <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
                  Cliente
                  <span>
                    ${Number(current?.lltpais?.customer_price ?? 0).toFixed(0)}
                  </span>
                </p>
              </div>
            </div>
          );
        }
      }
    },
    {
      name: 'addresses',
      label: 'Domicilios',
      options: {
        display: false
      }
    },
    {
      name: 'puntos',
      label: 'Domicilios',
      options: {
        download: false,
        customBodyRenderLite: (index) => {
          return (
            <div className="flex flex-col items-start">
              {data[index].locations.map((location, index) => (
                <div key={index} className="mb-1 flex items-start">
                  <div className="flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 p-1 text-white">
                    {index + 1}
                  </div>
                  <span className="ml-1">
                    {createDomicilioText(location.address)}
                  </span>
                </div>
              ))}
              <Button
                shape="circle"
                className="w-full p-2"
                onClick={() => onLocationsDetails(data[index])}
                color="blue"
              >
                <div className="flex items-center gap-1">Info</div>
              </Button>
            </div>
          );
        }
      }
    },
    {
      name: 'coordinationObservation',
      label: 'Obs. Coordinación'
    },
    {
      name: 'serviceType',
      label: 'Tipo de servicio',
      options: {
        display: false
      }
    },
    {
      name: 'serviceType',
      label: 'Servicio',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div className="flex items-center space-x-2">
            <p>
              {data[index].serviceType === ViajeTrasladoTipoServicio.EJECUTIVO
                ? null
                : data[index].serviceType}
            </p>{' '}
            {data[index].hasPriority && (
              <FontAwesomeIcon icon={faStar} color="orange" />
            )}
          </div>
        )
      }
    },
    {
      name: 'id',
      label: 'ID'
    },
    {
      name: 'platformId',
      label: 'Plataforma'
    },
    {
      name: 'assigned_driver',
      label: 'Chofer',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div className="flex flex-col items-center">
            <p>{data[index].assigned_driver?.codigo}</p>
            <p className="text-center text-xs">
              {data[index].assigned_driver?.nombreCompleto}
            </p>
          </div>
        )
      }
    },
    {
      name: 'driver_name',
      label: 'Chofer - Código',
      options: {
        display: false
      }
    },
    {
      name: 'driver_price',
      label: 'Precio del chofer'
    },
    {
      name: 'business_unit',
      label: 'Unidad de Negocio'
    },
    {
      name: 'passengers',
      label: 'Pasajeros',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div className="flex flex-col">
            {data[index].passengers.map((el) => (
              <span>{el}</span>
            ))}
          </div>
        )
      }
    },
    {
      name: 'passengers_name',
      label: 'Pasajeros',
      options: {
        display: false
      }
    },
    {
      name: 'state',
      label: 'Estado',
      options: {
        customBodyRenderLite: (index) => {
          const currentTravel = data[index];
          const currentState = currentTravel.state;
          const states = currentTravel.states;
          const canceledTravelInfo =
            states?.find((el) => el.type === 'Cancelado') ?? null;
          return (
            <div className="flex gap-3 items-center justify-center">
              <p>{currentState}</p>
              {canceledTravelInfo?.datetime ? (
                <IconButton
                  onClick={() =>
                    setCanceledTravelInfo({
                      ...canceledTravelInfo,
                      observation: currentTravel?.cancel_observation ?? ''
                    })
                  }
                  aria-label="Refresh"
                  size="small"
                >
                  <InfoIcon className="text-gray-300" />
                </IconButton>
              ) : null}
            </div>
          );
        }
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        download: false,
        customBodyRenderLite: (index) => {
          const currentTravel = data[index];
          return (
            <div className="flex flex-col gap-2">
              <div className="flex space-x-2">
                <Button
                  onClick={() => onReservationCopy(currentTravel)}
                  variant="light"
                >
                  Copiar
                </Button>
                {onReservationShowDetails(currentTravel.id)}
              </div>
              <Button
                variant="text"
                className={tw({
                  hidden: currentTravel.lltpais // Si es LLT-pais no mostrar el botón para link de seguimiento.
                })}
                onClick={async () => {
                  await copyReservationPublicNewLinkToClipboard(
                    currentTravel.id
                  );
                  snackbar.enqueueSnackbar(
                    'Nuevo link de seguimiento copiado',
                    {
                      variant: 'info'
                    }
                  );
                }}
              >
                Copiar link
              </Button>
              <ViajeLLTPaisEdit
                currentTravel={currentTravel}
                onOpenEditLLtPais={onOpenEditLLtPais}
              />
            </div>
          );
        }
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        title={
          tableQuery.isFetching && (
            <div className="flex items-center gap-4">
              <CircularProgress size={30} />
              <p className="text-gray-500">Cargando...</p>
            </div>
          )
        }
        data={data}
        options={{
          elevation: 0,
          selectableRows: 'none',
          search: false,
          filter: false,
          sort: false,
          ...tableQuery.serverSideOptions
        }}
        columns={columns}
      />
    </div>
  );
}
