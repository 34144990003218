import { Button, Modal } from '@vadiun/react-components';
import { Field, Form, Formik, FormikProps } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import { useRef } from 'react';
import * as Yup from 'yup';

import { TextField as TextFieldMaterial } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
// import { useSuperQuery } from '@vadiun/react-hooks';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import useChoferes from 'app/chofer/services/ChoferService';
import { useLLTPais } from '../hooks/useLLTPais';
// import { ChoferPartialModel } from '../models/Chofer';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  travel: any | undefined;
  onEditSuccess: () => void;
}

const EditLLTPais = ({ isOpen, travel, handleClose, onEditSuccess }: Props) => {
  const formRef = useRef<FormikProps<any>>(null);

  formRef.current?.setTouched({
    driver: true,
    providerPrice: true,
    customerPrice: true,
    observation: true
  });

  const driverRepository = useChoferes();
  const driversQuery = useSuperQuery(driverRepository.getChoferes);
  const { isLoading, onEditTravel } = useLLTPais();

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose();
        formRef.current?.resetForm();
      }}
      title="Editar Traslado LLT-Pais"
      subtitle={
        <div className="w-fit text-gray-400">
          <p>#{travel?.id}</p>
        </div>
      }
      body={
        <Formik
          enableReinitialize
          validationSchema={Yup.object().shape({
            driver: Yup.mixed().required('Ingrese un Chofer').nullable(),
            providerPrice: Yup.number().required(
              'Ingrese un costo de proveedor'
            ),
            customerPrice: Yup.number().required('Ingrese un costo de cliente'),
            observation: Yup.string()
              .required('Ingrese una observacion')
              .nullable()
          })}
          initialValues={{
            driver: travel.assigned_driver,
            providerPrice: travel?.lltpais?.provider_price,
            customerPrice: travel?.lltpais?.customer_price,
            observation: null
          }}
          onSubmit={async (data) => {
            await onEditTravel(travel.id, {
              driver_id: data.driver.id,
              provider_price: data.providerPrice,
              customer_price: data.customerPrice,
              observation: data.observation
            });
            onEditSuccess();
          }}
          innerRef={formRef}
        >
          {(formik) => (
            <Form noValidate>
              <Field
                name="driver"
                component={Autocomplete}
                options={driversQuery.data || []}
                fullWidth
                getOptionLabel={(option: any) =>
                  `${option.codigo} - ${option.nombreCompleto}`
                }
                className="w-1/2"
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextFieldMaterial
                    {...params}
                    error={
                      formik.touched['driver'] && !!formik.errors['driver']
                    }
                    helperText={formik.errors['driver']}
                    label="Chofer"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

              <div className="flex gap-4 mt-4 mb-4">
                <Field
                  name="providerPrice"
                  component={TextField}
                  label="Costo proveedor"
                  variant="outlined"
                  type="number"
                />
                <Field
                  name="customerPrice"
                  label="Costo cliente"
                  variant="outlined"
                  component={TextField}
                  type="number"
                />
              </div>
              <Field
                name="observation"
                label="Observaciones"
                variant="outlined"
                component={TextField}
                fullWidth
              />

              <div className="mt-8 flex justify-end">
                <Button type="submit" variant="contained" isLoading={isLoading}>
                  Guardar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

export default EditLLTPais;
